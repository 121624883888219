<template>
  <div class="release-require">
    <div class="top-banner">
      <img alt="" class="banner-image" src="@/assets/fabu/banner.png">
      <div class="wrapper">
        <p class="b-title">需求沟通</p>
      </div>
    </div>
    <p class="mtb-40 f28 wrapper text-center">
      请完善以下信息，让我们了解您的需求
    </p>
    <div class="request-form wrapper">
      <el-form ref="form" :model="form" :rules="rules" class="demo-ruleForm" label-position="top" label-width="100px">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="您的称呼" prop="name">
              <el-input v-model="form.name" placeholder="请填写您的称呼"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="form.phone" placeholder="便于我们工作人员与您联系"></el-input>
            </el-form-item>
            <el-form-item label="您的公司名称" prop="companyName">
              <el-input v-model="form.companyName" placeholder="请填写您的公司名称"></el-input>
            </el-form-item>
            <el-form-item label="您的公司所在地" prop="city">
              <el-input v-model="form.city" placeholder="请填写您的公司所在地"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="描述您的需求" prop="message">
              <el-input v-model="form.message" :autosize="{ minRows: 15, maxRows: 15}" maxlength="2000" placeholder="请简单描述您的需求" show-word-limit type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="text-center">
            <div class="xuqiu mtb-40" @click="sendMessage">
              提交需求
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="us">
      <div class="wrapper flex us-box">
        <span class="b-title">联系我们</span>
        <div class="r-box">
          <el-row>
            <el-col :span="8">
              <img alt="" src="@/assets/fabu/fabu-icon2.png">
              <p class="b-desc">025 5260 0022</p>
            </el-col>
            <el-col :span="8">
              <img alt="" src="@/assets/fabu/fabu-icon3.png">
              <p class="b-desc">cs@iriets.com</p>
            </el-col>
            <el-col :span="8">
              <img alt="" src="@/assets/fabu/fabu-icon1.png">
              <p class="b-desc">雨花台区宁双路沁恒科技园D栋205</p>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        phone: "",
        companyName: "",
        city: "",
        message: ""
      },
      rules: {
        name: [{ required: true, message: "  ", trigger: "blur" }],
        phone: [{ required: true, message: "  ", trigger: "blur" }],
        message: [{ required: true, message: "  ", trigger: "blur" }]
      }
    };
  },
  created() {},
  methods: {
    sendMessage() {
      const that = this;
      const data = {
        ...this.form
      };
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios({
            method: "post",
            url: window.configObj.baseUrl + "/website/upload",
            data
          })
            .then(response => {
              //这里使用了ES6的语法
              console.log(response.data); //请求成功返回的数据
              if (response.data.MsgCode == "1") {
                this.$message.success("发送成功！");
                that.$refs.form.resetFields();
              } else {
                this.$message.error("发送失败！");
              }
            })
            .catch(() => {});
        }
      });
    }
  },
  components: {}
};
</script>

<style lang="less" scoped>
.us {
  height: 225px;
  background: url("../assets/fabu/us.png") no-repeat;
  background-size: 100% 100%;

  .us-box {
    height: 100%;
  }

  .b-title {
    font-size: 24px;
    color: #fff;
    width: 200px;
    flex: none;
    margin-top: 40px;
  }

  .r-box {
    flex: 1;
    text-align: center;
    margin-top: 50px;

    .b-desc {
      margin-top: 20px;
      color: #fff;
    }
  }
}
</style>
